import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CDBRating, CDBContainer } from 'cdbreact';
import {
  fanlinkEmailRegistration,
  getPublicFanlink,
  postFanlinkPlatformClick,
  postFanlinkPlatformPresave,
  postFanlinkSpotifyPresave,
  postFanlinkVisit
} from '../../modules/fanlink/core/_requests';
import { useDocumentTitle, useInterval } from 'usehooks-ts';
import './promo_link.scss';
import { ReleaseFanlink } from '../../modules/catalog/core/_models';
import { DateTime } from 'luxon';
import { getPlatfomData } from '../../modules/catalog/generics/utils';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EmailRegistration } from '../../modules/fanlink/core/_models';
import { AxiosResponse } from 'axios';
import Skeleton from 'react-loading-skeleton';
import FanlinkPageSkeleton from './PromoLinkSkeleton';
import { OAuthService } from '../../modules/fanlink/core/oauth.service';
import React from 'react';
import PromoLinkPageSkeleton from './PromoLinkSkeleton';
import {
  getPublicPromo,
  postPromoRating,
  postPromoVisit
} from '../../modules/promo/core/_requests';
import { AudioPlayer } from '../../modules/audio_player/AudioPlayer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import StarRating from '../../modules/catalog/generics/StarRating';
import { useFingerprint } from '../../modules/user/core/fingerprint';

function useQueryString() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PromoLinkPage = function () {
  const params = useParams();
  const location = useLocation();
  const query = useQueryString();
  const queryClient = useQueryClient();
  const [isVisitSent, setIsVisitSent] = useState(false);
  const [isRatingSent, setRatingSent] = useState(false);
  const [isDownloadEnabled, setDownloadEnabled] = useState(false);
  const [downloadTimer, setDownloadTimer] = useState(10);
  const { currentFingerprint } = useFingerprint();

  const [spotifyPresaved, setSpotifyPresaved] = useState(false);

  // GET FANLINK
  const { data: promo } = useQuery(
    ['promo', params['*']],
    async () => {
      const { data: promo } = await getPublicPromo(params['*']!);
      return promo;
    },
    { refetchOnWindowFocus: false, staleTime: 300 * 1000 }
  );

  useDocumentTitle(
    promo ? 'Promo: ' + promo?.track.title + ' | labelmanager.io' : 'Promo | labelmanager.io'
  );

  useInterval(
    () => {
      setDownloadTimer(downloadTimer - 1);
    },
    downloadTimer > 0 ? 1000 : null
  );

  useEffect(() => {
    document.body.style.backgroundImage = `none`;
    document.body.style.backgroundColor = `#000`;

    setTimeout(() => {
      setDownloadEnabled(true);
    }, 10000);
  }, []);

  // FANLINK VISIT TRACKING
  useEffect(() => {
    if (promo && currentFingerprint && isVisitSent == false) {
      postPromoVisit(promo.uid!, currentFingerprint).then((response: AxiosResponse) => {});
      setIsVisitSent(true);
    }
  }, [promo, currentFingerprint]);

  async function sendRating(rating: number) {
    if (!promo) return;

    await postPromoRating(promo.uid!, rating, currentFingerprint, null);

    setRatingSent(true);

    if (isDownloadEnabled == false) {
      setDownloadEnabled(true);
      setDownloadTimer(0);
    }

    return promo;
  }

  function videoURL(videoId: string | null | undefined) {
    if (videoId) {
      return (
        'https://www.youtube.com/embed/' +
        videoId +
        '?autohide=1&autoplay=1&disablekb=1&color=white&showinfo=0&fs=0&controls=2&modestbranding=1&rel=0&mute=1'
      );
    }
    return '';
  }

  return (
    <>
      {promo && (
        <>
          <div
            className="background"
            style={{
              backgroundImage: 'url("' + promo!.track.artwork + '")',
              position: 'fixed'
            }}></div>
        </>
      )}
      <div className="background-overlay"></div>

      <div className="content d-flex flex-column flex-column-fluid align-items-center">
        <div className="container">
          <div className="blurred-bg"></div>

          {!promo ? (
            <>
              <PromoLinkPageSkeleton />
            </>
          ) : (
            <>
              <div className="row mt-20">
                <div className="col-sm-6 mt-5 mt-sm-0 text-center">
                  <img
                    src={promo?.track.artwork}
                    className="img-responsive"
                    style={{ width: '80%', height: 'auto' }}
                    alt={promo?.track.title}
                  />
                </div>
                <div className="col-sm-6 mt-5 mt-sm-0">
                  <div className="row">
                    <div className="col text-center">
                      <h3 className="text-white fs-2">
                        {promo.organization_name} has sent you a new promo
                      </h3>
                      <h1 className="text-white fs-1 mt-5">{promo?.track.title}</h1>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      {/* <p className="text-white fs-1">{promo?.description}</p> */}
                      <p className="text-white">{promo.description}</p>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-10 mb-10">
                    <div className="col">
                      <AudioPlayer audio_url={promo.track.listening_url!} id={promo.uid!} />
                    </div>
                  </div>

                  {isRatingSent == false ? (
                    <>
                      <div className="row">
                        <div className="col text-center">
                          <p className="text-white fs-4">Your feedback on this track :</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <StarRating onRating={(value: number) => sendRating(value)} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col text-center">
                          <p className="text-white fs-4">Thank you for your feedback!</p>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row mt-10">
                    <div className="col text-center">
                      <button
                        disabled={isDownloadEnabled == false}
                        onClick={() => (window.location.href = promo.track.download_url!)}
                        className="btn btn-success btn-lg btn-square text-white">
                        Download this promo {downloadTimer > 0 ? '(' + downloadTimer + ')' : <></>}
                      </button>
                    </div>
                    {/* <div className="col text-start">
                      <div className="btn btn-danger btn-lg btn-square btn-outline-danger text-white">
                        SKIP
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row fixed-bottom logo-row">
                <div className="col-md-12">
                  <div className="brand float-right">
                    <a href="https://www.labelmanager.io" target="_blank">
                      <img src="/media/logos/lm/logo-text.png" />
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PromoLinkPage;
